import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import SEO from './SEO';
import Layout from '../layouts/index';
import Card from './Card';

const Batch = ({ data: { batchesJson: batch } }) => {
  const startups = batch.startups.sort((a, b) => a.header.localeCompare(b.header));
  return (
    <Layout bodyClass="page-startups">
      <SEO title="Startups" />
      <div className="intro">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>{ batch.header }</h1>
              <p>
                { batch.description }
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-5 pb-5 pt-md-7 pb-md-7">
        <div className="row justify-content-center">
          <div className="team col-12 pt-4 pb-4 text-center">
            <h1>Startups</h1>
          </div>
        </div>

        <div id="partners" className="row justify-content-center">
          <div className="card-deck">
            {startups.map(startup => (
              <div key={ startup.header } className="card">
                <Card card={startup} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

Batch.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
    ]),
  ).isRequired,
};

export const query = graphql`
  query($id: String!) {
    batchesJson(id: { eq: $id }) {
      header
      link
      subheader
      startups {
        header
        subheader
        description
        link
        image
      }
      description
    }
  }
`;

export default Batch;
